import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search"
export default class extends Controller {
  submit() {
    this.element.requestSubmit();
    const bsOffcanvas = window.bootstrap.Offcanvas.getInstance('#search_fields')
    if (bsOffcanvas !== null) {
      bsOffcanvas.hide();
    }
  }
}
